const App__tab = {
    node: null,
    controls: null,
    tabActiveClass: 'tab__item--active',
    contentMap: new Map(),
    dropdownControl: null,
    dropdown: null,
  
    init: function() {
      App.tab.node = document.querySelector('[data-hook=tab]')
      if ( !App.tab.node) {
        return false;
      }

      App.tab.controls = document.querySelectorAll('[data-hook=tab__control]')

      if (!App.tab.controls?.length) {
        return false;
      }

      App.tab.controls.forEach((control) => {
        const tabId = control.getAttribute('data-tab-id')
        const section = document.querySelector(`[data-section-id=${tabId}]`)

        if(tabId && section) {
            control.addEventListener('click', (evt) => {
                App.tab.onTabClick(evt.target)
            })

            App.tab.contentMap.set(control, section)
        }
      })

      App.tab.dropdownControl = document.querySelector('[data-hook=tab__dropdown-control]')
      App.tab.dropdown = document.querySelector('[data-hook=tab__dropdown]')

      if (App.tab.dropdownControl && App.tab.dropdown) {
        App.tab.dropdownControl.addEventListener('click', App.tab.toggleDropdown)
      }
    },

    onTabClick: function(target) {
        App.tab.contentMap.forEach((content, control) => {
            control.parentNode.classList.remove(App.tab.tabActiveClass)
            content.setAttribute('hidden', true)
        })

        target.parentNode.classList.add(App.tab.tabActiveClass)
        App.tab.contentMap.get(target).removeAttribute('hidden')

        App.tab.dropdownControl.textContent = target.textContent
        App.tab.toggleDropdown()
    },

    toggleDropdown: function() {
      App.tab.node.classList.toggle('is-dropdown-open')
    }
  
  }
  
  export default App__tab;
  